<template>
  <div class="component-label">
    <div>{{ text }}</div>
    <div v-if="hintText" ref="hintWrap" class="hint__wrap">
      <div class="hint__icon">?</div>
      <div :style="hintPositionStyle" class="hint__text">
        <span>{{ hintText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComponentLabel',
  props: {
    text: { type: String, default: () => '' },
    hintText: { type: String, default: () => '' },
  },
  data() {
    return {
      hintPositionStyle: {},
    };
  },
  methods: {
    // checkHintPosition() {
    //   this.$nextTick(() => {
    //     const hintWrap = this.$refs.hintWrap;
    //     if (!hintWrap) return;
    //
    //     const hintText = hintWrap.querySelector('.hint__text');
    //     const hintRect = hintText.getBoundingClientRect();
    //     const windowWidth = window.innerWidth;
    //
    //     if (hintRect.right > windowWidth) {
    //       this.hintPositionStyle = {
    //         left: 'auto',
    //         right: '0',
    //       };
    //     } else if (hintRect.left < 0) {
    //       this.hintPositionStyle = {
    //         left: '0',
    //         right: 'auto',
    //       };
    //     } else {
    //       this.hintPositionStyle = {
    //         left: '-100%',
    //         right: 'auto',
    //       };
    //     }
    //   });
    // },
  },
  mounted() {
    // this.checkHintPosition();
    // window.addEventListener('resize', this.checkHintPosition);
  },
  beforeUnmount() {
    // window.removeEventListener('resize', this.checkHintPosition);
  },
};
</script>

<style lang="scss" scoped>
.component-label {
  color: var(--table-column-text-header-color);
  font-size: 12px;
  display: inline-flex;
  position: relative;
  width: 100%;

  & > div {
    margin-bottom: 4px;
    cursor: pointer;
    font-weight: 400;
  }

  .hint {
    &__wrap {
      margin-left: 5px;
    }

    &__icon {
      background: rgba(0, 0, 0, 0.17);
      color: rgba(225, 225, 225, 1);
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      cursor: pointer;
      transform: translate(0px, -1px);

      &:hover {
        & + .hint__text {
          opacity: 1;
          z-index: 10;
        }
      }
    }

    &__text {
      opacity: 0;
      z-index: -1;
      transition: all 0.3s ease-in-out;
      position: absolute;
      bottom: 105%;
      left: 0;
      width: 100%;
      font-size: 11px;
      color: #fff;
      font-weight: 500;

      span {
        background: rgba(0, 0, 0, 0.9);
        padding: 6px 8px;
        display: inline-block;
        border-radius: var(--main-border-radius);
      }
    }
  }
}
</style>
