<template>
  <div class="wrap">
    <div class="table">
      <div class="header">
        <div
          v-for="each in columnDefs"
          :key="each"
          :class="[{ hidden: each.hidden, sortable: each.sort }, 'item']"
          :data-field="getField(each.field)"
          :style="{ flex: each.flex ? each.flex : 0 }"
          @click.stop="sort"
        >
          {{ each.name }}
          <span class="direction">{{ getField(each.field) !== sortField.field ? '' : sortField.order === 'asc' ? '⇑' : '⇓' }}</span>
          <div v-if="addFilter(each)" class="search-wrap">
            <div class="img" @click.stop="searchToggle"></div>
            <div class="search-input-wrap">
              <input v-model="filters[each.field].value[0]" placeholder="Поиск..." type="text" />
            </div>
          </div>
        </div>
      </div>
      <div class="body" @click.stop="rowClick">
        <div
          v-for="row in processingRows(filteredTableData)"
          :key="row.key"
          :class="['main-row', row.symbol === selectedSymbol ? 'selected' : '', row.status === 'Ошибка' ? 'error' : '']"
          :data-key="row.key"
          :data-symbol="row.symbol"
        >
          <div class="row">
            <template v-for="column in columnDefs" :key="column.field">
              <div v-if="column.field === 'delete'" v-show="row.deletedRow" class="row-delete" @click.stop="deleteRow(row.data)">
                <div @click="animation"><img :src="icons.trash" alt="" /></div>
              </div>
              <div
                v-else-if="column.field === 'favoriteRow' && row.favoriteRow !== undefined && user.type === 'manager'"
                :title="row.favoriteRow ? 'Удаалить из HOT' : 'Добавить в HOT'"
                class="add-favorite"
                @click.stop="favorite(row.data)"
              >
                <div @click="animation"><img :src="row.favoriteRow ? icons.starFill : icons.star" alt="" /></div>
              </div>
              <div v-else-if="column.field === 'cancel'" v-show="row.deletedRow" class="row-cancel" @click.stop="cancelRow(row.data)">
                <div @click="animation"><img :src="icons.cross" alt="" /></div>
              </div>
              <div
                v-else
                :class="[column.field === 'symbol' ? 'key' : '', 'item']"
                :style="{
                  flex: column.flex || 0,
                  padding:
                    row.favoriteRow !== undefined && hasFavorite && column.field === 'symbol' && user.type === 'manager'
                      ? '5px 10px 5px 16px'
                      : '',
                }"
              >
                <div :class="[column.color ? utils.getColorClass(getValue(column, row)) : '', 'item-title']">
                  {{ utils.formatValue(getValue(column, row), column.type) }}
                </div>
              </div>
            </template>
          </div>
          <div v-if="row.symbol === selectedSymbol && row.details !== undefined && row.details.row.length > 0" class="detail-info">
            <component-table :columnDefs="row.details.column" :rows="row.details.row" title="" />
          </div>
        </div>
      </div>
      <div class="footer"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
import utils from '@/utils';
import animation from '@/utils/animation';

export default {
  name: 'ComponentTable',
  emits: ['cancel', 'delete', 'select', 'favorite'],
  data() {
    return {
      utils,
      icons,
      sortField: {}, // 'asc', // 'desc'
      filters: {},
    };
  },
  components: {},
  props: {
    columnDefs: { type: Array, requred: true },
    rows: { type: Array, default: () => [] },
    setSort: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters({
      selectedSymbol: 'getSelectedSymbol',
      selectedWl: 'getSelectedWl',
      user: 'getUser',
    }),
    hasFavorite() {
      return this.columnDefs.some((column) => column.field === 'favoriteRow');
    },
    filteredTableData() {
      return this.rows.filter((item) => {
        return Object.entries(this.filters).every(([key, filter]) => {
          return item[key].toLowerCase().includes(filter.value[0].toLowerCase());
        });
      });
    },
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
    addFilter(column) {
      if (column.search === undefined) return false;
      if (!(column.field in this.filters))
        this.filters[column.field] = {
          field: column.field,
          type: column.search,
          value: [''],
        };
      return true;
    },
    searchToggle(e) {
      this.filters[e.target.closest('.item').dataset.field].value[0] = '';
      e.target.closest('.search-wrap').classList.toggle('active');
      e.target.parentNode.querySelector('.search-input-wrap').querySelector('input').focus();
    },
    getValue(column, row) {
      if (typeof column.field === 'string') return row[column.field];
      if (Array.isArray(column.field)) {
        const result = [];
        for (const item of column.field) result.push({ value: row[item.field], type: item.type });
        return result;
      }
      return null;
    },
    rowClick(e) {
      const data = e.target.closest('.row').parentNode.dataset;
      this.$emit('select', data);
    },
    animation(e) {
      animation.compression(e);
    },
    cancelRow(data) {
      this.$emit('cancel', data);
    },
    deleteRow(data) {
      this.$emit('delete', data);
    },
    favorite(data) {
      this.$emit('favorite', data);
    },
    getField(field) {
      if (Array.isArray(field)) return field[0].field;
      return field;
    },
    sort(event) {
      const target = event.target;
      if (target.classList.contains('sortable')) {
        let field = target.dataset.field;
        if (Object.keys(this.sortField).length === 0 || this.sortField.field !== field)
          return void (this.sortField = { field, order: 'asc' });
        if (this.sortField.order === 'asc') return void (this.sortField.order = 'desc');
        if (this.sortField.order === 'desc') return void (this.sortField = {});
      }
    },
    processingRows(rows) {
      const localRows = rows.map((row) => ({ ...row }));
      if (Object.keys(this.sortField).length === 0) return localRows;
      return localRows.sort((a, b) => {
        const order = this.sortField.order === 'asc' ? 1 : -1;
        return order * (a[this.sortField.field] > b[this.sortField.field] ? 1 : a[this.sortField.field] < b[this.sortField.field] ? -1 : 0);
      });
    },
  },
  watch: {},
  created() {},
  mounted() {
    if (Object.keys(this.setSort).length > 0) this.sortField = this.setSort;
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.detail-info {
  padding: 0 0 5px 5px;
  background: var(--input-alt-default-bg);
  position: relative;
  box-shadow: -1px 1px 3px inset rgb(35 29 38);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--table-totals-divider-line-color);
  }

  .table {
    .item {
      font-size: 11px !important;
    }

    .header {
      box-shadow: -1px 1px 3px inset rgb(35 29 38);
    }

    .body {
      .item {
        box-shadow: 0 10px 12px inset rgb(35, 29, 38);
      }
    }
  }
}

.detail-order {
  background-color: var(--input-alt-default-bg);
  display: flex;
  padding: 5px 25px;
  justify-content: space-between;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.row {
  display: flex;
}

.table {
  overflow-y: auto;

  .header {
    background: var(--table-header-bg-color);
    box-shadow: -1px 1px 3px var(--table-header-shadow-color);
    color: var(--table-column-text-header-color);
    font-size: 12px;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 2;

    .item {
      border: none;
      font-weight: 400;
      position: relative;

      .title {
        pointer-events: none;
      }

      span.direction {
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translate(0, -50%);
      }

      &[data-value='symbol'] {
        z-index: 10;
      }
    }
  }

  .main-row {
    &.selected,
    &:hover {
      background-color: var(--table-row-bg-hover-color);

      .row-delete {
        pointer-events: all;

        img {
          opacity: 1;
          transform: scale(1);
        }

        &:not(:empty) + .item {
          .item-title {
            transform: translate(10px, 0);
          }
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &.error {
      position: relative;

      & > * {
        font-weight: 500;
        color: rgba(76, 104, 108, 0.71);
      }
    }
  }

  .row {
    color: var(--table-text-color);
    background-color: transparent;
    transition: background-color 0.2s ease-in;
    position: relative;
    cursor: pointer;
  }

  .item {
    padding: 5px 10px;
    border: 1px solid var(--table-totals-divider-line-color);
    border-right: none;
    border-top: none;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //order: 0;

    &:last-child {
      border-right: 1px solid var(--table-totals-divider-line-color);
    }

    &.hidden {
      display: none;
    }
  }
}

.key {
  font-weight: bold;
}

.item-title {
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-delete {
  pointer-events: none;
  cursor: pointer;
  padding: 0 4px;
  width: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  & > div {
    width: 100%;
  }

  img {
    transform: scale(0.5);
    transition: all 0.2s ease;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: contain;
  }
}

.add-favorite {
  position: absolute;
  top: 50%;
  left: 2px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0px, -50%);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.row-cancel {
  @extend .row-delete;
  right: auto;
  left: 0;
}

.search-wrap {
  .img {
    width: 14px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url('@/assets/icon/search.svg');
    background-size: cover;
  }

  &.active {
    .search-input-wrap {
      display: flex;
    }

    .img {
      background-image: url('@/assets/icon/close.svg');
    }
  }

  .search-input-wrap {
    position: absolute;
    width: calc(100% - 16px);
    left: 0;
    top: 2px;
    display: none;

    input {
      width: 100%;
    }
  }
}
</style>
