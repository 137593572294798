<template>
  <div class="setting-line">
    <div class="logo">
      <router-link :to="{ name: 'index' }" />
      <img :src="img.logo" alt="" />
    </div>

    <div class="center">
      <div class="center-top">
        <div v-show="$route.name !== 'order' || $route.name === 'index'" class="flex separator">
          <div class="title">Счет</div>
          <div class="select-wrap">
            <c-select v-model="localAccountSelected" :filters="optionFilter" :options="localAccounts" :user="user" scale searchable />
          </div>
        </div>

        <div class="hide-mobile separator">
          <div class="title">Клиент</div>
          <div class="select-wrap" style="font-size: 12px; width: auto">{{ formatName(fio) }}</div>
        </div>

        <!--        <div @click="pullPersonalData">1</div>-->

        <div v-show="$route.name === 'order' || $route.name === 'index'">
          <chart-setting />
        </div>
        <!--        v-if="user.type === 'manager' && !isMobile""-->
        <div v-if="user.type === 'manager' && !isMobile" class="open-options" @click="showOptions">
          <div class="options-icon">
            <img :src="icons.options" alt="" />
          </div>
        </div>
      </div>
    </div>

    <setting-menu />

    <c-modal :visible="modals.boarding.visible" size="small" title="Открытие счета" @close="modals.boarding.visible = false">
      <account-open @close="modals.boarding.visible = false" />
    </c-modal>

    <c-modal :visible="modals.profile.visible" size="small" title="Экономический профиль" @close="modals.profile.visible = false">
      <profile @close="modals.profile.visible = false" />
    </c-modal>

    <c-modal :visible="modals.about.visible" size="small" title="Приветствие" @close="modals.about.visible = false">
      <about />
    </c-modal>

    <c-modal
      ref="modalOptions"
      :visible="modals.options.visible"
      confirmation
      size="large"
      title="Опционы"
      @close="modals.options.visible = false"
    >
      <options-component />
    </c-modal>

    <conformation-order
      :data="modals.orderConfirm.data"
      :visible="modals.orderConfirm.visible"
      @confirm="modalConfirm('order')"
      @close="modals.orderConfirm.visible = false"
      @refuse="modals.orderConfirm.visible = false"
    />

    <conformation-short
      :data="modals.shortConfirm.data"
      :visible="modals.shortConfirm.visible"
      @confirm="modalConfirm('short')"
      @close="modals.shortConfirm.visible = false"
      @refuse="modals.shortConfirm.visible = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { img, icons } from '@/assets/svg-img';
import OptionsComponent from '@/components/OptionsComponent.vue';
import SettingMenu from '@/components/UI/SystemMenu';
import ChartSetting from '@/components/Chart/ChartSetting.vue';
import CSelect from '@/components/UI/ComponentSelect.vue';
import CModal from '@/components/UI/ComponentModal.vue';
import AccountOpen from '@/components/Instructions/Boarding.vue';
import About from '@/components/Instructions/About.vue';
import Profile from '@/components/Instructions/Profile.vue';
import ConformationOrder from '@/components/conformation/ConformationOrder.vue';
import ConformationShort from '@/components/conformation/ConformationShort.vue';

export default {
  name: 'SettingLineComponent',
  components: {
    ConformationShort,
    ConformationOrder,
    CModal,
    ChartSetting,
    SettingMenu,
    CSelect,
    OptionsComponent,
    AccountOpen,
    About,
    Profile,
  },
  data() {
    return {
      img,
      icons,
      localAccounts: [{ value: null, text: 'Пусто' }],
      localAccountSelected: null,
      fio: null,
      brokers: { Alpaca: 'Al', IB: 'IB', 'Partner Finance Limited': 'PF', 'Freedom Finance Global PLC': 'G' },
      optionFilter: {},
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
      personalData: 'getPersonalData',
      accounts: 'getAccounts',
      accountSelected: 'getAccountSelected',
      timeFrameSelected: 'getTimeFrameSelected',
      timeUpdateTerminal: 'getTimeUpdateTerminal',
      selectedSymbol: 'getSelectedSymbol',
      user: 'getUser',
      modals: 'getModals',
      order: 'getOrder',
    }),
  },
  methods: {
    ...mapMutations({
      changeTimeFrameSelected: 'changeTimeFrameSelected',
      changeLevelI: 'changeLevelI',
      changeModals: 'changeModals',
    }),
    ...mapActions({
      pullUser: 'pullUser',
      pullAccounts: 'pullAccounts',
      pullPortfolio: 'pullPortfolio',
      pullOrders: 'pullOrders',
      pullSettings: 'pullSettings',
      pullWatchList: 'pullWatchList',
      autoUpdate: 'autoUpdate',
      updateAccountSelected: 'updateAccountSelected',
      sendOrder: 'sendOrder',
    }),
    async modalConfirm(name) {
      this.order.confirmation[name] = true;
      this.modals[name + 'Confirm'].visible = false;
      this.sendOrder();
      // if (this.isMobile) this.$router.push({ name: 'orders' });
    },
    openModalSettingChart() {
      if (!this.isMobile) return void (this.$refs.modalSettingTerminal.show = true);
      this.$router.push({ name: 'setting' });
    },
    formatName(name) {
      if (!name) return 'Пусто';
      const words = name.split(' ', 3);
      if (words.length > 2) return words[0] + ' ' + words[1].at(0).toLocaleUpperCase() + '.' + words[2].at(0).toLocaleUpperCase() + '.';
      else if (words.length === 2) return words[0] + ' ' + words[1].at(0).toLocaleUpperCase() + '.';
      else return name;
    },
    localList(accounts) {
      const list = [];
      for (const key in accounts) {
        let bName = this.brokers[accounts[key].broker_name] != undefined ? this.brokers[accounts[key].broker_name] : 'Other';
        list.push({
          value: accounts[key].account,
          text: bName + ' ' + accounts[key].account + ' ' + accounts[key].full_name,
          broker: accounts[key].broker_name,
          contactId: accounts[key].contact_id,
          contractId: accounts[key].contract_id,
          lastName: accounts[key].full_name,
          managerId: accounts[key].manager_id,
          manager: accounts[key].manager,
          type: accounts[key].contract_status,
          // label: bName + ' ' + accounts[key].account + ' ' + accounts[key].full_name,
        });
      }
      return list;
    },
    localSort(list) {
      return list.sort((a, b) => {
        if (a.broker > b.broker) return -1;
        else if (a.broker < b.broker) return 1;
        else {
          if (a.lastName > b.lastName) return 1;
          else if (a.lastName < b.lastName) return -1;
          else return 0;
        }
      });
    },
    addManagerIdFilter() {
      const key = 'managerId';
      this.optionFilter[key] = {
        field: key,
        type: 'checkbox',
        text: 'Собственные',
        checked: true,
        value: [this.user.id],
      };
    },
    showOptions() {
      this.modals.options.visible = true;
    },
  },
  watch: {
    personalData: {
      handler(newState) {
        if (newState.options.status === 'opening') {
          this.modals.about.visible = true;
        }
      },
      deep: true,
    },
    accounts(newState) {
      if (Object.keys(newState).length > 0) this.localAccounts = this.localSort(this.localList(newState));
    },
    accountSelected(newState) {
      if (newState.length > 0) {
        this.localAccountSelected = newState;
        this.fio = this.accounts[newState] ? this.accounts[newState].full_name : null;
      }
    },
    localAccountSelected(newState) {
      // console.log(newState);
      if (this.accountSelected !== newState) this.updateAccountSelected(newState);
    },
    timeFrameSelected(newState) {
      this.localTimeFrameSelected = newState;
    },
    localTimeFrameSelected(newState) {
      if (this.timeFrameSelected !== newState)
        this.updateSettings({
          setting: { chart: { timeframe: newState } },
          update: true,
        });
    },
    timeUpdateTerminal(newState) {
      this.autoUpdate(newState);
    },
  },
  async created() {
    // const start = performance.now();
    await this.pullUser();
    this.addManagerIdFilter();
    // console.log('personal :', this.personalData);
    // console.log('pullUser: ', performance.now() - start);
    await this.pullAccounts();
    // console.log('pullAccounts: ', performance.now() - start);
    await this.pullSettings();
    // console.log('pullSettings: ', performance.now() - start);
    await this.pullWatchList();
    window.api.marketData.on('quote', (data) => {
      // console.table([data]);
      this.changeLevelI(data);
    });
    window.api.ptfin.on('updateOrder', (data) => {
      console.log(data);
      this.pullOrders();
    });
    // document.addEventListener('visibilitychange', function (data) {
    // console.table([data]);
    // });
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.logo {
  height: 45px;
  position: relative;

  a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  img {
    height: 100%;
  }
}

.select-wrap {
  width: 190px;
  height: 24px;
}

.center-top {
  display: flex;
  align-items: center;
  gap: 30px;
}

.setting-line {
  height: var(--setting-line-height);
  padding: 4px 10px 8px;
  background-image: linear-gradient(to right, var(--widget-header-grad-color-1), var(--widget-header-grad-color-2));
  box-shadow: 0 1px 0 0 var(--widget-header-shadow-color);
  display: flex;
  align-items: flex-end;
  gap: 30px;
  @include breakpoint-down(md) {
    gap: 15px;
    padding: 4px 5px 8px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 40;
    box-shadow: 0 2px 0 0 var(--widget-header-shadow-color);

    @media (orientation: landscape) {
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
    }
  }

  .page-title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 4px;
  }

  .center {
    display: flex;
    justify-content: flex-end;
    margin-right: auto;
    @include breakpoint-down(md) {
      margin-right: auto;
    }
  }

  .separator {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      bottom: 5px;
      right: -15px;
      width: 1px;
      background: var(--table-totals-divider-line-color);
      box-shadow: inset 0 1px 0 var(--input-default-shadow-inner-color);
      @include breakpoint-down(md) {
        display: none;
      }
    }
  }

  .open-options {
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    &:hover {
      .options-icon {
        box-shadow: -1px 1px 3px var(--table-header-shadow-color);
        transform: translate(0, -1px);
      }
    }
  }

  .options-icon {
    width: 30px;
    height: 30px;
    border-radius: var(--main-border-radius);
    border: solid 1px var(--table-totals-divider-line-color);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 1px 1px var(--table-header-shadow-color);
    background: var(--input-alt-default-bg);
    margin-right: 4px;
    padding: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.2s ease-in-out;
    }
  }
}
</style>
