/* eslint-disable no-unused-vars */
import utils from '@/utils';
import storeDefault from '@/store/default.js';
import boarding from '@/store/boarding.js';
import { createStore } from 'vuex';

const makePortfolioByRow = ({ account, date_time, instrument, quantity = 0.0, price = 0.0, average = 0.0, transactions = [] }) => {
  const each = {
    account,
    date_time,
    instrument,
    quantity,
    price,
    day: { quantity: 0.0, cash: 0.0, commission: 0.0, close: 0.0 },
    average,
    transactions: {},
    pnl: { day: 0.0, all: 0.0 },
  };
  for (const transaction of transactions) each.transactions[transaction.transaction_id] = makeTransaction(transaction);
  return each;
};

const makeTransaction = ({ transaction_id, date_time, price, quantity, commission = 0.0, list }) => {
  return { id: transaction_id, date_time, price, quantity, commission, list };
};

const makeTradesByOrder = (order, currentPrice) => {
  return [
    {
      transaction_id: order.transaction_id,
      commission: 0.0,
      commission_currency: 'USD',
      date_time: order.status_time,
      quantity: order.filled,
      price: order.price || currentPrice,
      list: 'orders',
    },
  ];
};

const getPrice = (symbol, endTime) => {
  if (symbol === undefined) return 0.0;
  return symbol.rtc || symbol.lp;
};

const keyFloat = [
  'time_extended',
  'quantity',
  'filled',
  'price',
  'money',
  'commission',
  'average',
  'cost',
  'amount',
  'stop',
  'stop_price',
  'multiplier',
  'assets',
  'cashes',
  'bp',
];
const keyData = ['status_time', 'date_time', 'accepted_time'];

const processingData = (item) => {
  if (Array.isArray(item)) {
    for (const each of item) processingData(each);
  } else {
    for (const key of Object.keys(item)) {
      if (keyData.includes(key)) {
        item[key] = new Date(item[key] * Math.pow(10, 13 - String(item[key]).length));
      } else if (keyFloat.includes(key)) {
        if (item[key] !== null) item[key] = parseFloat(item[key] || 0.0);
      } else if (['trades', 'purchase_details', 'transactions', 'total', 'instrument'].includes(key)) {
        processingData(item[key]);
      }
    }
  }
};

const updateAsset = async (asset) => {
  let quantity = 0.0;

  asset.day.quantity = asset.day.cash = asset.day.commission = asset.day.close = asset.average = 0.0;
  const transactions = Object.keys(asset.transactions)
    .map((key) => asset.transactions[key])
    .sort((a, b) => (a.date_time > b.date_time ? 1 : a.date_time < b.date_time ? -1 : 0));
  for (const each of transactions) {
    if (each.date_time > asset.date_time) {
      asset.day.quantity += each.quantity;
      asset.day.cash += -1 * utils.round(each.quantity * each.price, 5);
      asset.day.commission += each.commission;
    }
    if (each.quantity > 0.0 && quantity + each.quantity !== 0.0) {
      asset.average = utils.round((asset.average * quantity + each.price * each.quantity) / (quantity + each.quantity), 5);
    }
    quantity += each.quantity;
  }
  if (parseFloat(asset.day.quantity + asset.quantity) === 0.0) {
    asset.day.close = Math.abs(utils.round(asset.day.cash / asset.day.quantity, 5));
  }
};

const updateCash = (portfolioItems) => {
  const cashId = portfolioItems.findIndex((item) => item.instrument.symbol === 'USD');
  portfolioItems[cashId].day.quantity = portfolioItems[cashId].day.cash = 0.0;
  for (let key = 0; key < portfolioItems.length; key++) {
    if (key !== cashId) {
      portfolioItems[cashId].day.quantity += portfolioItems[key].day.cash;
      portfolioItems[cashId].day.cash += portfolioItems[key].day.cash;
    }
  }
};

const portfolioBySymbol = (portfolio, symbol) => {
  for (const each of portfolio.items) {
    if (each.instrument && each.instrument.symbol === symbol) return each.quantity + each.day.quantity;
  }
  return 0;
};

const getActiveOrders = (symbol, orders) => {
  return orders.filter((item) => ['approval', 'pending', 'accepted'].includes(item.status) && item.instrument.symbol === symbol);
};

const isShort = (instruction, portfolio, orders) => {
  const newQuantity = (instruction.deal.orders[1].action === 'sell' ? -1 : 1) * Math.abs(instruction.deal.general.quantity);
  const portfolioQuantity = portfolioBySymbol(portfolio, instruction.deal.general.symbol);

  let ordersQuantity = 0;
  for (const item of getActiveOrders(instruction.deal.general.symbol, orders)) {
    ordersQuantity += item.quantity;
  }
  return portfolioQuantity + ordersQuantity + newQuantity < 0;
};

const textSendOrder = (each) => {
  let text = 'Приказ успешно создан: ';
  text += each.full_name + ' ' + each.advice.symbol;
  text += each.advice.open > 0 ? ` цена: ` + each.advice.open : ' ' + each.advice.type;
  text += `, количество: ` + each.advice.quantity + `<br />`;
  return text;
};

export default createStore({
  state: () => storeDefault.initialState(),
  getters: {
    isLoggedIn: (state) => !!state.token,
    getTOTP: (state) => state.totp,
    // authStatus: (state) => state.status,
    getNotifications: (state) => state.notifications,
    getUser: (state) => state.user,
    getPersonalData: (state) => state.personalData,
    getProfile: (state) => state.profile,
    getDocuments: (state) => state.documents,
    getModals: (state) => state.modals,
    getConfirmation: (state) => state.confirmation,
    getAccountSelected: (state) => state.accountSelected,
    getAccounts: (state) => state.accounts,
    getSymbols: (state) => state.symbols,
    getSelectedSymbol: (state) => state.selectedSymbol,
    getLevelI: (state) => state.levelI,
    getPortfolio: (state) => state.portfolio,
    getOrders: (state) => state.orders,
    getOptions: (state) => state.options,
    getExperations: (state) => state.experations,
    getOrderStatus: (state) => state.orderStatus,
    getOrderType: (state) => state.orderType,
    getOrder: (state) => state.order,
    getSelectedWl: (state) => state.selectedWl,
    getWatchList: (state) => state.watchList,
    getSearchList: (state) => state.searchList,
    getChartType: (state) => state.chartType,
    getChartMainIndicators: (state) => state.chartMainIndicators,
    getChartSubIndicator: (state) => state.chartSubIndicator,
    getTimeFrameSelected: (state) => state.timeFrameSelected,
    getTimeUpdateTerminal: (state) => state.timeUpdateTerminal,
    getIntervalNumber: (state) => state.intervalNumber,
    getUpdateWatchList: (state) => state.updateWatchList,
    isMobile: (state) => window.innerWidth <= state.widthDisplay,
    getChartHeight: (state) => state.chartHeight,
    // removedIndicator: (state) => state.removeIndicator,
  },
  mutations: {
    toggleChartHeight(state) {
      state.chartHeight = !state.chartHeight; // меняем значение переменной на противоположное
    },
    changeUser(state, data) {
      for (const key in data) state.user[key] = data[key];
      state.user.wl = state.user.type === 'manager' && !state.isMobile ? ['main', 'hot'] : ['main'];
    },
    changePersonalData(state, data) {
      for (const key in data) state.personalData[key] = data[key];
    },
    changeProfile(state, data) {
      for (const key in data) state.profile[key] = data[key];
    },
    changeDocuments(state, data) {
      state.documents = [];
      for (const each of data) state.documents.push(each);
    },
    changeModals(state, data) {
      for (const key in data) state.modals[key] = data[key];
    },
    changeOptions(state, data) {
      state.options = data;
    },
    changeExperations(state, data) {
      state.experations = data;
    },
    changeConfirmation(state, data) {
      state.confirmation = data;
    },
    changeToken(state, token) {
      token ? localStorage.setItem('session.token', token) : localStorage.removeItem('session.token');
      state.token = token;
    },
    changeTOTP(state, cognito) {
      state.totp = cognito;
    },
    changeSymbols(state, data) {
      state.symbols = data;
    },
    deleteSymbol(state, symbol) {
      delete state.symbols[symbol];
    },
    changeNotifications(state, data) {
      state.notifications.push(storeDefault.initialNotification(data));
    },
    cleanNotification(state) {
      state.notifications = [];
    },
    changeSelectedSymbol(state, symbol) {
      state.selectedSymbol = symbol;
    },
    changeLevelI(state, data) {
      state.levelI[data.symbol] = { bid: data.bid, bidSize: data.bid_size, ask: data.ask, askSize: data.ask_size };
    },
    changePortfolio(state, data) {
      state.portfolio = data;
    },
    updatePortfolio(state) {
      const symbols = state.symbols;
      const portfolio = state.portfolio;
      if (portfolio.items.length === 0) return;
      const leverage = 2;
      for (const key in portfolio.total) portfolio.total[key] = 0.0;

      for (const each of portfolio.items) {
        const quantity = each.quantity + each.day.quantity;
        if (each.instrument.asset_category === 'CASH') {
          each.price = ['USD'].includes(each.instrument.symbol) ? 1.0 : each.price || getPrice(symbols[each.instrument.symbol]);
          portfolio.total.cashes += quantity * each.instrument.multiplier * each.price;
          portfolio.total.bp += quantity * each.instrument.multiplier * each.price;
        } else {
          if (each.instrument.listing_exchange !== 'Empty') each.price = getPrice(symbols[each.instrument.symbol]);
          if (quantity === 0.0) {
            each.pnl.all = (each.day.close - each.average) * each.quantity * each.instrument.multiplier;
          } else {
            // each.pnl.day = each.quantity * each.instrument.multiplier * symbols[each.instrument.symbol].ch;
            each.pnl.all = (each.price - each.average) * quantity * each.instrument.multiplier;
            portfolio.total.assets += quantity * each.instrument.multiplier * each.price;
            portfolio.total.bp +=
              each.instrument.asset_category === 'STK' ? (quantity * each.instrument.multiplier * each.price) / leverage : 0.0;
          }
        }
        portfolio.total.amount += quantity * each.instrument.multiplier * each.price;
      }
      // console.log('end: ', JSON.stringify(state.portfolio));
    },
    changeSelectedWl(state, data) {
      state.selectedWl = data;
    },
    changeWatchList(state, data) {
      state.watchList = data;
    },
    changeSearchList(state, data) {
      state.searchList = data;
    },
    changeAccounts(state, accounts) {
      state.accounts = accounts;
    },
    changeListOrders(state, data) {
      state.orders = data;
    },
    setDefaultOrder(state) {
      state.order = storeDefault.initialOrder();
      state.order.deal.general.symbol = state.selectedSymbol;
      state.order.list = [storeDefault.initialAccount(state.accounts[state.accountSelected])];
      // console.log(JSON.stringify(state.order));
    },
    changeOrder(state, data) {
      if ('symbol' in data) state.order.deal.general.symbol = data.symbol;
      if ('quantity' in data) state.order.deal.general.quantity = Math.abs(data.quantity);
      if ('action' in data) state.order.deal.orders[1].action = data.action;
      if ('tif' in data) state.order.deal.orders[1].tif = data.tif;
      if ('account' in data) state.order.list = [data.account];
      if ('type' in data) state.order.deal.orders[1].type = data.type;
      if ('limitPrice' in data) state.order.deal.orders[1].limitPrice = data.limitPrice;
      if ('triggerPrice' in data) state.order.deal.orders[1].triggerPrice = data.triggerPrice;
    },
    changeChartType(state, type) {
      state.chartType = type;
    },
    changeTimeFrameSelected(state, time) {
      if (state.timeFrameSelected !== time) state.timeFrameSelected = time;
    },
    changeAccountSelected(state, account) {
      if (account && state.accountSelected !== account) state.accountSelected = account;
    },
    changeTimeUpdateTerminal(state, timeUpdate) {
      if (state.timeUpdateTerminal !== timeUpdate) state.timeUpdateTerminal = timeUpdate;
    },
    changeIntervalNumber(state, intervalNumber) {
      if (state.intervalNumber !== intervalNumber) state.intervalNumber = intervalNumber;
    },
    changeChartMainIndicators(state, main) {
      if (state.chartMainIndicators !== main) state.chartMainIndicators = main;
    },
    changeChartSubIndicator(state, indicator) {
      if (state.chartSubIndicator !== indicator) state.chartSubIndicator = indicator;
    },
    changeUpdateWatchList(state, flag) {
      state.updateWatchList = flag;
    },
  },
  actions: {
    restartServer() {
      return window.api.marketData.restartWS();
    },
    updateWS() {
      return window.api.ptfin.updateWS();
    },
    async login({ commit, getters }, { login, password, code }) {
      let result = await window.api.auth.signin({ login, password });
      if (result.status === 'external') {
        let external = {};
        if (code === '') {
          external = await utils.externalAuth({ login, password });
          if (external.type === 'totpRequired') {
            commit('changeTOTP', external);
            return { status: 'totp', text: 'Input code' };
          }
        } else {
          external = await utils.externalTOTP({ cognito: getters.getTOTP, code });
        }

        if (external.type !== 'onSuccess') {
          result = { status: 'unlogged', text: 'Incorrect login or password', token: null };
        } else {
          if (result.refreshToken) {
            const revoke = await utils.revokeToken({ token: result.refreshToken });
            console.log('revokeToken: ', revoke);
            if (this.status === 200) window.api.auth.deleteSession({ token: result.refreshToken });
          }
          result = await window.api.auth.session({ login, password, session: external.session });
        }
      }
      if (result.status === 'logged') commit('changeToken', result.token);
      return { status: result.status, text: result.text };
    },
    async resetPassword({ commit }, formData) {
      return window.api.auth.resetPassword(formData);
    },
    // async logout({ dispatch, rootState }) {
    // dispatch('autoUpdate', null);
    // rootState.router.push({ name: 'login' });
    // },
    resetState({ state, dispatch }) {
      dispatch('autoUpdate', null);
      localStorage.removeItem('session.token');
      Object.assign(state, storeDefault.initialState);
    },
    async pullUser({ commit }) {
      commit('changeUser', await window.api.auth.userData());
    },
    async pullPersonalData({ getters, commit }) {
      const response = await window.api.user.getData({ id: getters.getUser.id });
      if (response.error) console.error('pullPersonalData Error', response.text, response.data);
      commit('changePersonalData', boarding.makePersonalData(response.data));
    },
    async pullProfile({ getters, commit }) {
      const response = await window.api.user.getProfile({ id: getters.getUser.id });
      if (response.error) console.error('pullProfile Error', response.text, response.data);
      commit('changeProfile', boarding.makeProfile(response.data));
    },
    async pullDocuments({ getters, commit }) {
      const customer = getters.getAccounts[getters.getAccountSelected];
      if (customer !== undefined) {
        commit('changeDocuments', await window.api.user.documents({ id: customer.contact_id }));
      }
    },
    async pullAccounts({ commit }) {
      const data = await window.api.ptfin.accounts();
      const accounts = {};
      // if (Object.keys(data).length === 0) accounts[null] = storeDefault.initialAccount();
      // else
      for (const key in data) {
        accounts[key] = storeDefault.initialAccount(data[key]);
      }
      commit('changeAccounts', accounts);
    },
    async updateAccountSelected({ dispatch }, account) {
      await dispatch('updateSettings', { setting: { main: { account } }, update: true });
    },
    async updateSelectedSymbol({ getters, dispatch, commit }, symbol) {
      if (symbol && symbol !== getters.selectedSymbol && !['USD', 'EUR'].includes(symbol)) {
        commit('changeSelectedSymbol', symbol);
        commit('changeOrder', { symbol });
        dispatch('pullQuoteSymbol', symbol);
        dispatch('updateSettings', { setting: { main: { symbol } } });
      }
    },
    async updateSettings({ dispatch }, { setting, update = false }) {
      await window.api.settings.update({ updates: setting });
      if (update) dispatch('pullSettings');
    },
    async pullSettings({ getters, commit, dispatch }) {
      const main = await window.api.settings.get({ type: 'main' });
      const chart = await window.api.settings.get({ type: 'chart' });
      commit('changeTimeUpdateTerminal', main.refresh);

      if (main.confirmation === undefined) {
        main.confirmation = true;
        dispatch('updateSettings', { setting: { main: { confirmation: main.confirmation } } });
      }
      commit('changeConfirmation', main.confirmation);

      const account = main.account || Object.keys(getters.getAccounts)[0] || null;
      commit('changeAccountSelected', account);
      commit('changeOrder', { account: storeDefault.initialAccount(getters.getAccounts[account]) });

      if (main.symbol) {
        dispatch('updateSelectedSymbol', main.symbol);
        dispatch('pullDataSymbols');
      }

      commit('changeTimeFrameSelected', parseInt(chart.timeframe));
      commit('changeChartType', chart.type);
      if (!Array.isArray(chart.main)) chart.main = chart.main === '' ? [] : [{ name: chart.main, value: chart.main }];
      commit('changeChartMainIndicators', chart.main);
      commit('changeChartSubIndicator', chart.sub);

      if (account) {
        await dispatch('pullPortfolio');
        dispatch('pullOrders');
        commit('updatePortfolio');
      }
    },
    async pullWatchList({ getters, commit, dispatch }) {
      // const watchList = await window.api.watchList.get({ name: 'main' });
      const watchList = await window.api.watchList.get();
      const instruments = [];
      for (const each of watchList) {
        each.price = 0.0;
        each.change = 0.0;
        each.changeP = 0.0;
        if (each.instrument === null || Object.keys(each.instrument).length === 0) {
          each.instrument = { symbol: each.symbol, asset_category: null, listing_exchange: each.source };
        }
        instruments.push(each.instrument);
      }
      commit('changeUpdateWatchList', true);
      commit('changeWatchList', watchList);
      dispatch('addSymbols', { instruments });

      if (!getters.getSelectedSymbol) {
        dispatch('updateSelectedSymbol', instruments[0].symbol);
        dispatch('pullDataSymbols');
      }
    },
    async addInstrumentWL({ getters, dispatch }, { name, instrument }) {
      if (getters.getWatchList.findIndex((each) => each.name === name && each.symbol === instrument.symbol) !== -1) {
        return void console.log('Error: ', 'symbol with this name already exists');
      }
      // console.log('innstrument >:', name, instrument);
      if (instrument.exchange === undefined) instrument.exchange = instrument.listing_exchange;
      const res = await window.api.watchList.add({ name, instrument });
      if (res) return dispatch('pullWatchList');

      // console.log('store res :', res, name, instrument);
      return void console.log('Error addInstrumentWL: ', res);
    },
    async deleteInstrumentWL({ getters, commit, dispatch }, { name, instrument }) {
      if (instrument.source === undefined) instrument.source = instrument.listing_exchange;
      const res = await window.api.watchList.delete({ name, instrument });
      if (res) {
        commit('deleteSymbol', instrument.symbol);
        // window.api.marketData.deleteQuoteSymbol({ instrument.symbol });
        return dispatch('pullWatchList');
      }
      return void console.log('Error deleteInstrumentWL: ', res);
    },
    autoUpdate({ commit, getters, dispatch }, newTimeUpdateTerminal) {
      const intervalNumber = getters.getIntervalNumber;
      if (intervalNumber === null && getters.isLoggedIn) setTimeout(() => dispatch('pullDataSymbols'), 1000);
      clearTimeout(intervalNumber);
      let intervalId = null;
      if (newTimeUpdateTerminal !== null)
        intervalId = setInterval(
          () => {
            dispatch('pullOrders');
            dispatch('pullDataSymbols');
          },
          parseInt(newTimeUpdateTerminal) * 1000,
        );
      commit('changeIntervalNumber', intervalId);
    },
    async pullDataSymbols({ commit, getters, dispatch }) {
      const symbols = getters.getSymbols;
      const data = await window.api.marketData.getDataSymbols({ symbols: Object.keys(symbols) }).catch((error) => {
        if (error.code === 403) {
          commit('changeToken', '');
        } else {
          throw error;
        }
      });
      for (const symbol in data) {
        for (const key in data[symbol]) {
          symbols[symbol][key] = data[symbol][key];
        }
      }
      commit('changeSymbols', symbols);

      dispatch('updateWatchList');
      commit('updatePortfolio');
    },
    async pullQuoteSymbol({ getters, commit }) {
      const quotes = await window.api.marketData.getQuoteSymbol({ symbol: getters.getSelectedSymbol });
      commit('changeLevelI', quotes);
    },
    async pullChartData({ commit }, { instrument, period, limit = 1000 }) {
      return window.api.marketData.addChartSymbols({ instruments: [instrument], period, limit, wait: 2000 });
    },
    async pullPortfolio({ commit, getters, dispatch }) {
      const portfolio = storeDefault.initialPortfolio();
      commit('changePortfolio', portfolio);
      const data = await window.api.ptfin.portfolio({ accounts: [getters.getAccountSelected] });
      processingData(data.rows);
      if (data.rows !== undefined) {
        for (const row of data.rows) {
          row.date_time -= 3 * 60 * 60 * 1000;
          if (portfolio.date_time === null || portfolio.date_time > row.date_time) portfolio.date_time = row.date_time;
          if (row.transactions === undefined) row.transactions = row.purchase_details.transactions;
          portfolio.items.push(makePortfolioByRow(row));
          dispatch('addSymbols', { instruments: [{ ...row.instrument }] });
        }
      }
      commit('changePortfolio', portfolio);
    },
    async pullOrders({ commit, getters, dispatch }) {
      const portfolio = getters.getPortfolio;
      const orders = await window.api.ptfin.orders({ accounts: [getters.getAccountSelected], days: 30 });
      processingData(orders);
      // orders.sort((a, b) => (a.status_time > b.status_time ? 1 : a.status_time < b.status_time ? -1 : 0));
      let updateTotal = false;
      for (const order of orders) {
        let updateOrder = false;
        let assetId = null;
        const maxDateTrdes = order.trades.reduce((max, a) => (a.date_time > max ? a.date_time : max), portfolio.date_time);
        if (
          (order.trades.length === 0 && order.status_time > portfolio.date_time && Math.abs(order.filled) > 0.0) ||
          maxDateTrdes > portfolio.date_time
        ) {
          assetId = portfolio.items.findIndex((item) => item.instrument.symbol === order.instrument.symbol);
          if (assetId === -1) {
            portfolio.items.push(
              makePortfolioByRow({
                account: order.account,
                date_time: portfolio.date_time,
                instrument: order.instrument,
              }),
            );
            assetId = portfolio.items.findIndex((item) => item.instrument.symbol === order.instrument.symbol);
          }

          if (order.trades.length === 0) order.trades = makeTradesByOrder(order, getPrice(getters.getSymbols[order.instrument.symbol]));
          for (const trade of order.trades) {
            if (!(trade.transaction_id in portfolio.items[assetId].transactions)) {
              updateTotal = updateOrder = true;
              if (trade.list === undefined) trade.list = 'trades';
              portfolio.items[assetId].transactions[trade.transaction_id] = makeTransaction(trade);
            }
          }
        }
        dispatch('addSymbols', { instruments: [{ ...order.instrument }] });
        if (updateOrder) updateAsset(portfolio.items[assetId]);
      }
      if (updateTotal) updateCash(portfolio.items);

      commit('changePortfolio', portfolio);
      commit('changeListOrders', []);
      commit('changeListOrders', orders);
    },
    addSymbols({ commit, getters }, { instruments }) {
      const symbols = getters.getSymbols;
      const levelI = getters.getLevelI;
      let update = false;
      for (const each of instruments) {
        if (each.symbol !== 'USD' && each.listing_exchange !== 'Empty') {
          if (!(each.symbol in symbols)) {
            update = true;
            symbols[each.symbol] = storeDefault.initialSymbolData(each);
            commit('changeSymbols', symbols);
          }
          if (!(each.symbol in levelI)) {
            update = true;
            commit('changeLevelI', storeDefault.initialLevelI(each.symbol));
          }
        }
      }
      if (update) {
        window.api.marketData.addQuoteSymbols({ instruments: symbols });
      }
    },
    async cancelOrder({ dispatch, commit }, order) {
      const res = await window.api.ptfin.cancelOrder({ order });
      if (res) return dispatch('pullOrders');
      return void console.log('Error deleteOrder: ', res);
    },
    updateWatchList({ commit, getters }) {
      const symbols = getters.getSymbols;
      const watchList = getters.getWatchList;
      for (const each of watchList) {
        each.price = symbols[each.symbol] ? symbols[each.symbol].rtc || symbols[each.symbol].lp : 0.0;
        each.change = symbols[each.symbol] ? symbols[each.symbol].ch : 0.0;
        each.changeP = symbols[each.symbol] ? symbols[each.symbol].chp / 100 : 0.0;
      }
      commit('changeWatchList', watchList);
    },
    async fetchInstrument({ commit }, needle) {
      const list = await window.api.marketData.symbolSearch(needle);
      commit('changeSearchList', list);
    },
    async sendOrder({ getters, commit, dispatch }) {
      if (!getters.getAccountSelected) return void console.log('not client');
      const instruction = getters.getOrder;
      const symbol = getters.getSymbols[instruction.deal.general.symbol];
      let instrument = { api: 'tv' };
      for (const each of ['symbol', 'source', 'type', 'description', 'currency_id', 'country_code', 'listed_exchange', 'isin', 'lp']) {
        instrument[each] = symbol[each];
      }

      if (!instruction.confirmation.order && getters.getConfirmation) {
        return void commit('changeModals', {
          orderConfirm: {
            visible: true,
            data: {
              action: instruction.deal.orders[1].action,
              type: instruction.deal.orders[1].type,
              instrument: { symbol: instruction.deal.general.symbol },
              quantity: instruction.deal.general.quantity,
              status: instruction.deal.general.status,
              limitPrice: instruction.deal.orders[1].limitPrice,
              triggerPrice: instruction.deal.orders[1].triggerPrice,
            },
          },
        });
      }

      if (!instruction.confirmation.short && isShort(instruction, getters.getPortfolio, getters.getOrders)) {
        return void commit('changeModals', { shortConfirm: { visible: true, data: {} } });
      }

      instruction.deal.general.date_time = new Date().valueOf();
      const response = await window.api.ptfin.sendOrder({ data: instruction, instrument });
      dispatch('pullOrders');
      commit('setDefaultOrder');
      if (response.success.length > 0) {
        for (const each of response.success) {
          commit('changeNotifications', { text: textSendOrder(each), style: 'red', type: 'success', duration: 2.5 });
        }
        return void console.log('sendOrder: success');
      }

      new Error('Error send order!!!');
    },
    async forgotPassword({ commit }, data) {
      // console.log('store :', data);
      await window.api.ptfin.resetPassword(data);
    },
    async terminalUser({ commit }, data) {
      return window.api.auth.register(data);
    },
    newKey({ commit }, data) {
      window.api.user.newKey(data);
    },
    inviteTelegram({ getters }) {
      window.api.user.invite({ type: 'telegram', user: getters.getAccounts[getters.getAccountSelected].contact_id });
    },
    async sendPhone({ commit, getters }, { form, options = {} }) {
      form.id = getters.getUser.id;
      options.type = 'phone';
      options.action = 'create';
      const response = await window.api.registration.enrichmen({ form, options });
      commit('changeUser', {
        id: response.data.contact.id,
        login: response.data.contact.phone_mobile,
        type: 'registration',
      });
      return response;
    },
    sendTaxNumber({ getters }, { form, options = {} }) {
      form.id = getters.getUser.id;
      options.type = 'taxNumber';
      options.action = 'create';
      return window.api.registration.enrichmen({ form, options });
    },
    async sendEnrichment({ getters }, { form, options = {} }) {
      form.id = getters.getUser.id;
      if (form.files !== undefined) {
        for (const file of form.files) {
          file.fileName = file.name;
          file.base64 = await utils.readFileBase64(file);
        }
      }
      options.action = 'enrichment';
      return window.api.instruction.sendForm({ form, options });
    },
    async deleteDocuments({ getters }, file) {
      file.id = getters.getUser.id;
      return window.api.instruction.deleteDocument(file);
    },
    async createInstruction({ getters }, { form, options = {} }) {
      form.id = getters.getUser.id;
      options.action = 'create';
      return window.api.instruction.sendForm({ form, options });
    },
    async signingInstruction({ getters }, { form, options = {} }) {
      form.id = getters.getUser.id;
      options.action = 'sign';
      return window.api.instruction.sendForm({ form, options });
    },

    async sendStrimDocs({ getters }, data) {
      const file = data.files[0];
      const uploader = window.application.metacom.createBlobUploader(file);
      await window.api.files.upload({
        streamId: uploader.id,
        name: file.name,
      });
      await uploader.upload();
      return { uploadedFile: file };
    },
    // async sendDocs({ getters }, data) {
    // const file = data.files[0];
    // const base64String = await readFileBase64(file);
    // const buffer = await readFileBuffer(file);
    // const responce = await window.api.user.sendDocs({ name: file.name, file: base64String });
    // console.log(responce);
    // },
    async pullExpiration({ commit }, data) {
      const expirations = await window.api.ptfin.expiration(data);
      commit('changeExperations', expirations);
    },
    async pullOptions({ commit }, data) {
      const options = await window.api.ptfin.options(data);
      commit('changeOptions', options);
    },
    async updateOptions({ dispatch }, symbol) {
      const response = await window.api.ptfin.updateOptions({ symbol });
      console.log(response);
      dispatch('pullExpiration', { symbol });
      return 'ok';
    },
  },
  modules: {},
});
